<template>
    <VModal name="promoCodeModal" class="notification-modal centered promocode-modal" width="100%" height="auto" :scrollable="true">
      <div class="vm-header">
        <div class="vm-header-left">
        </div>
        <div class="vm-header-center">
        </div>
        <div class="vm-header-right">
          <img src="@/assets/ic_nav_close.svg" alt="" @click="closePromoCodeModal">
        </div>
      </div>
      <div class="vm-content">
        <img v-if="!success" src="@/assets/notification/ic_notification_gift.svg" alt="">
        <img v-else src="@/assets/notification/ic_notification_success.svg" alt="">
        <h3 v-if="!success">
          Bonus-Code einlösen
        </h3>
        <h3 v-else>
          Glückwunsch!
        </h3>
        <p v-if="!success">
          Trage hier deinen Bonus-Code ein und nutze alle Premium-Vorteile! Verwende unbedingt die Emailadresse mit der du dich in der SportlerPlus-App registrierst (Registrierung per Email und Passwort, NICHT per Google, Apple, facebook)! Lade anschließend die Sportlerplus-App im App-Store und registriere dich. Viel Spaß!
        </p>
        <p v-else>
          Du bist nun Premium-Mitglied von SportlerPlus und hast bis zum <span>{{ premiumStatus.validTo.toLocaleDateString() }}</span> Zugriff auf alle exklusiven Inhalte.
        </p>
        <p class="error-message" v-if="codeInvalid">Der angegebene Code ist fehlerhaft. Bitte überprüfe deine Eingabe.</p>
        <v-text-field v-if="!success" v-model="code" placeholder="Bonus-Code" type="text" required></v-text-field>
        <v-text-field v-if="!success" v-model="email" placeholder="Email-Adresse" type="text" required></v-text-field>
        <v-btn v-if="!success" :disabled="validating" type="button" class="button button--primary" @click=submitCode>Einlösen</v-btn>
        <v-btn v-else type="button" class="button button--primary" @click=closePromoCodeModal>Alles klar</v-btn>
      </div>
    </VModal>
</template>


<script>
export default {
    name: 'PromoCodeModal',
    methods: {
        closePromoCodeModal() {
            this.$modal.hide("promoCodeModal");
            this.$emit("closePromoCodeModal");
        },
        submitCode() {
            this.validating = true;
            this.$store.dispatch('redeemCode', {
                code: this.code,
                email: this.email
            }).then( (res) => {
                this.validating = false;
                this.premiumStatus = res;
                if (res.validTo) {
                    this.success = true;
                    this.codeInvalid = false;
                }
                else {
                    this.codeInvalid = true;
                }
            });
        },
    },
    data() {
        return {
            code: "",
            email: "",
            success: false,
            codeInvalid: false,
            premiumStatus: null,
            validating: false,
        }
    }
}
</script>

<style lang="scss">
  .promocode-modal {

    .error-message {
        color: red;
    }

    .vm-content {

      img {
        margin-top: -16px;
      }

    }

    p {
      opacity: 1;
      color: rgba($colorBlack, .64);

      span {
        color: $colorBlack;
        font-weight: 600;
      }
    }
  }
</style>